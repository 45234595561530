import cs from 'classnames';
import { ReactNode } from 'react';

export type Props = {
  className?: string;
  noPadding?: boolean;
  children?: ReactNode;
};

const ContainerCard = ({ noPadding, className, children }: Props) => {
  return (
    <div
      className={cs(className, 'rounded-lg bg-primary shadow-md', {
        'py-6 px-4 sm:py-10 sm:px-12': !noPadding,
      })}
    >
      {children}
    </div>
  );
};

export default ContainerCard;
