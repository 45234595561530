import cs from 'classnames';
import React, { FunctionComponent } from 'react';

export type Props = {
  className?: string;
  padded?: boolean;
  insetNavBar?: boolean;
  widthConstrained?: boolean;
} & React.HTMLAttributes<HTMLDivElement>;

const Container: FunctionComponent<Props> = ({
  className,
  padded = true,
  insetNavBar,
  children,
  widthConstrained = true,
  ...props
}) => {
  return (
    <div
      className={cs('w-full h-screen overflow-y-auto', className, {
        'px-4 pt-4 pb-24 md:px-8 md:pt-8': padded,
        'md:pt-20': insetNavBar,
        ['max-w-6xl']: widthConstrained,
      })}
      {...props}
    >
      {children}
    </div>
  );
};

export default Container;
