import cs from 'classnames';
import { ReactNode } from 'react';

export type Props = {
  className?: string;
  size?: 'small' | 'regular';
  id: string;
  children?: ReactNode;
};

const FormError = ({ className, id, size = 'small', children }: Props) => {
  const sizeClass = {
    small: 'text-sm',
    regular: '',
  }[size];

  return (
    <div className={cs('text-danger', sizeClass, className)} id={id}>
      {children}
    </div>
  );
};

export default FormError;
