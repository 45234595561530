import cs from 'classnames';
import React, { ReactElement, ReactNode } from 'react';
import { ReusableComponentProps } from '../types';
import FormDescription from './FormDescription';
import FormError from './FormError';
import FormLabel from './FormLabel';

export type Props = ReusableComponentProps & {
  label: string;
  description?: string;
  error?: string | null;
  children?: ReactNode;
};

const FormElement = ({
  className,
  style,
  children,
  label,
  description,
  error,
}: Props) => {
  const firstChild = React.Children.toArray(children)[0] as ReactElement;
  if (!firstChild) {
    throw new Error('FormElement must have at least one child');
  }
  const elementID = firstChild.props.id;
  if (!elementID) {
    throw new Error(`"FormElement child ${firstChild.type} must have an ID`);
  }
  const errorElementID = `${elementID}-error`;
  const descriptionElementID = `${elementID}-description`;

  return (
    <fieldset className={cs(className)} style={style}>
      <FormLabel className="mb-1" htmlFor={elementID}>
        {label}
      </FormLabel>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child as ReactElement, {
          'aria-describedby': error
            ? errorElementID
            : description
              ? descriptionElementID
              : undefined,
          style: {
            ...(child as ReactElement).props.style,
            outline: error ? '#bf1848 auto 2px' : undefined,
          },
        });
      })}
      {error ? (
        <FormError className="mt-1" id={errorElementID}>
          {error}
        </FormError>
      ) : null}
      {description ? (
        <FormDescription className="mt-1" id={descriptionElementID}>
          {description}
        </FormDescription>
      ) : null}
    </fieldset>
  );
};

export default FormElement;
