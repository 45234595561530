import { Formik } from 'formik';
import { useRouter } from 'next/router';
import React, { useState } from 'react';
import Button from '../components/button/Button';
import ContainerCard from '../components/card/ContainerCard';
import Container from '../components/container/Container';
import FormElement from '../components/form/FormElement';
import FormError from '../components/form/FormError';
import Heading from '../components/heading/Heading';
import Input from '../components/input/Input';
import Loading from '../components/loading/Loading';
import Page from '../components/page/Page';
import { FirebaseAuthError } from '../utilities/errors/firebase-auth.errors';
import { getErrorCode } from '../utilities/errors/helpers';
import { login } from '../utilities/request/auth';
import { requireUnauthenticated } from '../utilities/require-authentication';
import { Routes, linkTo } from '../utilities/routes';
import { NextPageWithLayout } from './_app';

const Login: NextPageWithLayout = () => {
  const router = useRouter();
  const [errorMessage, setErrorMessage] = useState('');
  const redirect = router.query.redirect_uri as string;
  let redirectUrl: URL | null = null;
  if (redirect) {
    // Try to construct a URL out of the redirect URL; if it doesn't work,
    // it's likely a relative URL so we need the base URL.
    try {
      redirectUrl = new URL(redirect);
    } catch (_e) {
      // If this also throws, the redirect URL supplied is wrong. This is likely a bug so
      // we want an error to be thrown.
      redirectUrl = new URL(redirect, window.location.origin);
    }
  }

  return (
    <ContainerCard className="mx-auto max-w-xl">
      <Heading level={1} className="text-center">
        Pathfinder login
      </Heading>
      <Formik
        initialValues={{ email: '', password: '' }}
        validate={(values) => {
          const errors: {
            email?: string;
            password?: string;
          } = {};
          if (!values.email) {
            errors.email = 'Required';
          }
          if (!values.password) {
            errors.password = 'Required';
          }
          return errors;
        }}
        onSubmit={async (values, { setSubmitting }) => {
          setSubmitting(true);
          try {
            await login(values.email, values.password);
            if (
              redirectUrl != null &&
              redirectUrl.hostname === window.location.hostname
            ) {
              window.location.href = redirect;
            } else {
              window.location.href = linkTo(Routes.home);
            }
          } catch (error) {
            switch (getErrorCode(error)) {
              case FirebaseAuthError.WrongPassword:
              case FirebaseAuthError.UserNotFound:
                setErrorMessage('Please check your email or password.');
                return;
            }

            throw error;
          }
        }}
      >
        {({
          errors,
          touched,
          handleSubmit,
          handleChange,
          handleBlur,
          isSubmitting,
        }) => {
          return (
            <form onSubmit={handleSubmit} className="mx-auto mt-4 max-w-md">
              <FormElement
                label="Email"
                error={touched.email ? errors.email : ''}
              >
                <Input
                  className="w-full"
                  type="email"
                  id="email"
                  name="email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormElement>
              <FormElement
                className="mt-4"
                label="Password"
                error={touched.password ? errors.password : ''}
              >
                <Input
                  className="w-full"
                  type="password"
                  id="password"
                  name="password"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder=""
                />
              </FormElement>
              {errorMessage ? (
                <FormError size="regular" id="formError" className="mt-4">
                  {errorMessage}
                </FormError>
              ) : null}
              <div className="mt-4">
                <Button
                  type="submit"
                  disabled={isSubmitting}
                  className="mb-8 w-full"
                >
                  <Loading when={isSubmitting} className="mr-2" color="light" />
                  {isSubmitting ? 'Logging in...' : 'Log in'}
                </Button>
              </div>
            </form>
          );
        }}
      </Formik>
    </ContainerCard>
  );
};

Login.getLayout = (page) => (
  <Page title="Log in | Pathfinder" bg="secondary">
    <Container widthConstrained={true}>{page}</Container>
  </Page>
);

export default requireUnauthenticated(Login);
